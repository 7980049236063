import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { AttendancerecorderService } from '../attendancerecorder.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors: Array<any> = [{
    backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3), rgba(196,79,244,0.3)'],
    borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1), rgba(196,79,244,0.3)']
  }];
  public pieChartPlugins = [];
  totalCount = 0;
  totalCountPresent = 0;
  totalAbsent = 0;
  nationalTotalCount = 0;
  stateTotalCount = 0;

  nationalTotalPresentCount = 0;
  stateTotalPresentCount = 0;
  dataList1 = [];

  dataList = [];
  attendanceInquriy: any;
  constructor(private attendancerecorderService: AttendancerecorderService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.attendancerecorderService.getProductList().subscribe((response) => {
      this.dataList = response;
      console.log("abc", this.dataList)
      this.dataList.forEach(element => {
        if (this.dataList1[element.city]) {
          this.dataList1[element.city].push(element);

        } else {
          this.dataList1[element.city] = [];
          this.dataList1[element.city].push(element);
        }
      })

      setTimeout(() => {
        var stateDetailExpandTriggers = document.querySelectorAll('.state-detail-expand-trigger');
        for (var i = 0; i < stateDetailExpandTriggers.length; i++) {
          stateDetailExpandTriggers[i].addEventListener('click', function () {
            let parent_li = this.parentElement.parentElement.parentElement
            let table = parent_li.children.length ? parent_li.children[1] : false // 2nd child (i.e. on index 1) is table

            if (table) {
              if (table.classList.contains("hidden")) {
                table.classList.remove("hidden")
                // table.classList.replace("hidden", "block")
              } else {
                table.classList.add("hidden")
                // table.classList.replace("block", "hidden")
              }
              if (this.classList.contains('rotate-0')) {
                this.classList.replace('rotate-0', 'rotate-180')
                parent_li.classList.add("bg-gray-200")
              } else {
                this.classList.replace('rotate-180', 'rotate-0')
                parent_li.classList.remove("bg-gray-200")
              }
            } else {
              // alert("No state specific details as of now!")
            }
          });
        }
      }, 1000);
    })



    var groups = this.dataList.reduce(function (obj, item) {
      obj[item.city] = obj[item.city];
      obj[item.city].push(item.name);
      return obj;
    }, {});
    var myArray = Object.keys(groups).map(function (key) {
      return { city: key, name: groups[key] };
    });
    console.log("array", myArray);


    console.log("sss", this.dataList1)



    this.attendancerecorderService.getAttendanceInquiry().subscribe((response) => {
      this.attendanceInquriy = response;
      this.totalCount = response.total;
      this.totalCountPresent = response.totalPresent;
      this.totalAbsent = response.totalAbsent;
      //this.processResponseTotalCount(this);
      //this.processResponse(this);

    })
    this.getAttendanceInquriyInterval();
  }

  getAttendanceInquriyInterval() {
    var vm = this;
    setInterval(function () {
      vm.attendancerecorderService.getAttendanceInquiry().subscribe((response) => {
        vm.attendanceInquriy = response;
        vm.totalCount = response.total;
        vm.totalCountPresent = response.totalPresent;
        vm.totalAbsent = response.totalAbsent;
        //vm.processResponseTotalCount(vm);
        //vm.processResponse(vm);
      })

      vm.attendancerecorderService.getProductList().subscribe((response) => {
        vm.dataList = response;
      })
    }, 100000);

  }

  getStateWisePresentCount(name: string) {
    let stateDataCount = 0;
    let stateData = this.attendanceInquriy.list.filter(
      state => state.name.toLowerCase() === name.toLowerCase());
    if (stateData && stateData[0] && stateData[0].count) {
      stateDataCount = stateData[0].count;
    }
    return stateDataCount;
  }

  processResponseTotalCount(vm) {
    vm.nationalTotalCount = 0;
    vm.stateTotalCount = 0
    vm.attendanceInquriy.totalList.forEach(element => {
      if (element) {
        if (element.name.toLowerCase() == 'national') {
          vm.nationalTotalCount = element.count;
        } else {
          vm.stateTotalCount += element.count;
        }

      }
    });
  }
  processResponse(vm) {
    vm.pieChartLabels = [];
    vm.pieChartData = []
    vm.totalCountPresent = 0;
    vm.cityPeopleMap = [];
    vm.nationalTotalPresentCount = 0;
    vm.stateTotalPresentCount = 0;



    vm.attendanceInquriy.list.forEach(element => {
      if (element) {
        if (element.name.toLowerCase() == 'national') {
          vm.pieChartLabels.push('Internal');
          vm.nationalTotalPresentCount = element.count;
        } else {
          vm.pieChartLabels.push(element.name);
          vm.stateTotalPresentCount += element.count;
        }

        vm.pieChartData.push(element.count);
        vm.totalCountPresent += element.count;
      }
    });
  }

}
