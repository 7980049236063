<mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav class="app-sidenav">
      <mat-nav-list>
        <a mat-list-item href="https://example.com">
          <mat-icon matListIcon>account_box</mat-icon>
          <h3 matLine>About</h3>
        </a>
        <h3 matSubheader>Other</h3>
        <a mat-list-item target="_blank" href="https://sticky-toolbar-so.stackblitz.io">
          <mat-icon matListIcon>open_in_new</mat-icon>
          <h3 matLine>Open in new tab</h3>
        </a>
        <a mat-list-item target="_blank" href="https://stackblitz.com/edit/sticky-toolbar-so">
          <mat-icon matListIcon>code</mat-icon>
          <h3 matLine>View source code1</h3>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <menubar [opts]="opts"></menubar>
      <div class="example-sidenav-content">
        <ng-content select="[route]"></ng-content>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>