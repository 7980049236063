<!-- <mat-toolbar [color]="opts.color ? opts.color : 'primary'" [ngClass]="{'mat-elevation-z3': opts.hasEl}"
    style="position: fixed;">
    <button mat-icon-button (click)="toggleSidenav()" *ngIf="opts.sidenav">
        <mat-icon>menu</mat-icon>
    </button>
    {{opts.title}}
    <span fxFlex></span>

    <div>
        <a mat-icon-button [href]="url" target="_blank" matTooltip="Open in new tab">

            <span>
                About US
            </span>
        </a>
    </div>

    <div>

    </div>
    <span fxFlex></span>
    <a mat-icon-button [href]="url" target="_blank" matTooltip="Open in new tab">
        <mat-icon>
            shopping_cart
        </mat-icon>
    </a>
    <a mat-icon-button matTooltip="Login" (click)="login()">
        <mat-icon>
            login
        </mat-icon>
    </a>
</mat-toolbar> -->

<div id="menu-desktop">
	<div class="container">
		<nav class="navbar navbar-expand-md navbar-dark">
			<div class="navbar-collapse collapse">
				<ul class="navbar-nav d-flex align-items-center justify-content-between aos-init aos-animate" data-aos="fade-down"> 
					<li class="nav-item">
						<a class="nav-link " href="about-us">About us</a>
					</li>
					<li class="nav-item">
						<a class="nav-link active " href="our-range/">Our range</a>
					</li>
					<li class="nav-item">
						<a class="nav-link header-logo" href="/">Nuts for</a>
					</li>
					<li class="nav-item">
						<a class="nav-link " href="competition">Competition</a>
					</li>
					<li class="nav-item">
						<a class="nav-link " href="contact-us">Contact us</a>
					</li>
					<li class="nav-item" id="nav-item-shop">
						<a class="nav-link" href="https://sunvalleyshop.co.uk/collections/nuts-for" target="_blank">Shop</a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</div>