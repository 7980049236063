<div class="cdk-overlay-container">
    <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div class="cdk-global-overlay-wrapper" style="justify-content: center; align-items: center;">
        <div class="cdk-overlay-pane" style="max-width: 80vw; position: static;">
            <div tabindex="0" class="cdk-visually-hidden cdk-focus-trap-anchor" aria-hidden="true"></div>
            <div class="mat-dialog-container ng-tns-c139-26 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
                role="dialog" style="overflow-x: scroll;
          height: 86vh;
          width: 100%;">

                <h1 mat-dialog-title>
                    <ng-content select="[header]"></ng-content>
                </h1>
                <div mat-dialog-content>
                    <ng-content select="[content]"></ng-content>
                </div>
                <div mat-dialog-actions>
                    <ng-content select="[action]"></ng-content>
                </div>
            </div>
        </div>

    </div>

</div>