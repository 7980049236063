import { List } from 'immutable';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBy'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchelement: string, term: string, searchelementstring?: string): any {
    let valueArray;
    if (List.isList(items)) {
      valueArray = items.toJS();
    } else {
      valueArray = items;
    }

    if (searchelementstring != undefined && searchelementstring != '' && valueArray && valueArray.length > 0) {
      return valueArray.filter(item => (item[searchelement].toLowerCase().indexOf(term.toLowerCase()) !== -1) || (item[searchelementstring].toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }
    else {
      if (valueArray && valueArray.length > 0)
        return valueArray.filter(item => (item[searchelement].toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

  }
}

/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], order = '', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') { 
      if(order==='asc'){return value.sort()}
      else{return value.sort().reverse();}
    } // sort 1d array
    return orderBy(value, [column], [order]);
  }
}
