<header style="background-color: #ED6009; color: #450000">
    <div class="meeting-heading w-full h-full">
        <div
            class="container mx-auto px-4 flex flex-col xs:flex-row justify-center space-x-1 sm:space-x-8 items-center lg:block lg:relative text-center text-base my-4 font-bold">
            <div class="brand-image relative lg:absolute h-full flex flex-row items-center">
                <img src="./assets/img/logo1.png" alt="BJP logo"
                    class="h-24 sm:h-28 md:h-36 lg:mt-10 2xl:h-40 2xl:mt-16 xl: bg-white rounded-full p-1.5">
            </div>

            <h1
                class="brand-name text-2.5xl sm:text-4.5xl 2xl:text-6xl pt-3 xs:pt-8 pb-4 font-semibold flex flex-col lg:flex-row items-center lg:justify-center">
                <!-- National Executive Meeting Bharatiya Janata Party -->
                <span class="min-w-max sm:min-w-0 lg:pl-28 lg:mr-1.5 xl:pl-0 xl:mr-2">
                    विधायक दल बैठक, भाजपा
                </span>
            </h1>

        </div>
    </div>
    <div class="meeting-date w-full" style="background-color: #450000;">
        <div
            class="container mx-auto text-center text-2xl sm:text-3.5xl 2xl:text-4xl py-1 sm:py-2 2xl:py-3 text-white font-normal">
            11 December  2023
        </div>
    </div>
</header>

<main class="relative flex-grow py-12 xl:py-20">

    <div class="lg:max-w-screen-lg 2xl:max-w-screen-2xl mx-auto px-2 sm:px-4">

        <div class="total-stat py-6">
            <div class="relative rounded-2xl">
                <div class="absolute -top-12 sm:-top-14 w-56 rounded-t-2xl pr-36 p-4 pt-3"
                    style="background-color: #F49420;" style="background-color: #F49420;">
                    <h1 class="text-3xl sm:text-4xl">Attendance</h1>
                </div>

                <div
                    class="relative mt-10  bg-white border-2 border-grey-500 z-10 rounded-t-2xl grid grid-cols-4 divide-x-2 divide-grey-500 py-3 md:py-2">
                    <div class="mx-4 p-4 pl-1.5 sm:pl-4 md:pl-8">
                        <div class="count-title font-normal">
                            <h3 class="text-2xl">Expected</h3>
                        </div>
                        <div class="count font-semibold" style="color: #F49420;">
                            <h1 class="text-4xl">{{totalCount}}</h1>
                        </div>
                    </div>
                    <div class="mx-4 p-4 pl-1.5 sm:pl-4 md:pl-8">
                        <div class="count-title font-normal">
                            <h3 class="text-2xl">Present</h3>
                        </div>
                        <div class="count font-semibold" style="color: #F49420;">
                            <h1 class="text-4xl">{{totalCountPresent}}</h1>
                        </div>
                    </div>
                    <div class="mx-4 p-4 pl-1.5 sm:pl-4 md:pl-8">
                        <div class="count-title font-normal">
                            <h3 class="text-2xl">Yet to Register</h3>
                        </div>
                        <div class="count font-semibold" style="color: #F49420;">
                            <h1 class="text-4xl">{{totalAbsent}}</h1>
                        </div>
                    </div>
                </div>




                <!-- Accordian Starts - State Wise Stat -->
                <div class="state-wise-stat bg-white border-2 border-grey-500 rounded-b-2xl overflow-hidden">
                    <table class="state-detail bg-white w-full text-base md:text-xl">
                        <tbody>
                            <tr *ngFor="let info of dataList | sortBy:'asc':'attendanceId'"
                                class="border-1 border-gray-300 w-full">
                                <td class="profile-photo border-t-2 border-b-2 border-gray-100 py-2 w-2/12">
                                    <div class="w-full flex justify-center">
                                        <div
                                            class="id-profile-image relative h-14 w-14 flex flex-row items-center rounded-full overflow-hidden shadow-md">
                                            <img src="/assets/images/{{info.categoryA}}" alt="Profile Photo"
                                                class="h-full w-full object-cover">
                                        </div>

                                    </div>
                                </td>
                                <td class="name border-t-2 border-b-2 border-gray-100 py-2 w-3/12 font-semibold">
                                    {{info.name}}
                                </td>
                                <td class="designation border-t-2 border-b-2 border-gray-100 py-2 w-5/12">
                                    {{info?.designation}}
                                </td>
                                <td *ngIf="info.isAttendanceRecorded"
                                    class="attendance-status border-t-2 border-b-2 border-gray-100 py-2 w-2/12 h-full text-center">
                                    <div class="w-full flex justify-center">
                                        <div
                                            class="badge px-3 md:px-10 py-1 bg-green-500 rounded-full font-semibold text-white max-w-min">
                                            Present</div>
                                    </div>
                                </td>
                                <td *ngIf="!info.isAttendanceRecorded"
                                    class="attendance-status border-t-2 border-b-2 border-gray-100 py-2 w-2/12 h-full text-center">
                                    <div class="w-full flex justify-center">
                                        <div
                                            class="badge px-3 md:px-10 py-1 bg-red-500 rounded-full font-semibold text-white max-w-min">
                                            Yet to Register</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Accordian Ends - State Wise Stat -->
        </div>

    </div>


</main>





<script>

    // Accordian funtionality
    var stateDetailExpandTriggers = document.querySelectorAll('.state-detail-expand-trigger');
    for (var i = 0; i < stateDetailExpandTriggers.length; i++) {
        stateDetailExpandTriggers[i].addEventListener('click', function () {
            let parent_li = this.parentElement.parentElement.parentElement
            let table = parent_li.children.length ? parent_li.children[1] : false // 2nd child (i.e. on index 1) is table

            if (table) {
                if (table.classList.contains("hidden")) {
                    table.classList.remove("hidden")
                    // table.classList.replace("hidden", "block")
                } else {
                    table.classList.add("hidden")
                    // table.classList.replace("block", "hidden")
                }
                if (this.classList.contains('rotate-0')) {
                    this.classList.replace('rotate-0', 'rotate-180')
                    parent_li.classList.add("bg-gray-200")
                } else {
                    this.classList.replace('rotate-180', 'rotate-0')
                    parent_li.classList.remove("bg-gray-200")
                }
            } else {
                // alert("No state specific details as of now!")
            }
        });
    }

</script>